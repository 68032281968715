import * as React from "react"
import Clientes from "../components/commons/clientes"
import Image from "../images/illustracion/home.svg";
import Layout from "../components/layout/es"
import { Link } from "gatsby";


import { graphql } from 'gatsby'
import Portfolio from "../components/home/portfolio"
import Testimonios from "../components/home/testimonios"

// markup
const IndexPage = (props) => {

  return (
    <Layout transparent location={props.location} pageId="home" metaTitle="Desarrollo web en Madrid: Wordpress, Drupal, React | Crequs">
      <section className="main-banner-v3" style={{backgroundImage:'url(/img/hero-bg.jpg)'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="banner-txt">
                <h2>Desarrollo web<br />en Madrid</h2>
                <span>Expertos en Wordpress, Drupal y React</span>
                <a href="mailto:info@crequs.com" title="" className="btn-default3" >Escríbenos</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <Image />
            </div>
          </div>
        </div>
      </section>
      <section className="about_v1_sec">
        <div className="container">
          <div className="about_v1_content">
            <p>
              Somos un equipo <span>multidisciplinar</span> e <span>internacional</span> con un recorrido de más de 10 años en 
              desarrollo web. Nuestros ejes a la hora de llevar a cabo cualquier proyecto son: escalabilidad, mantenibilidad y desarrollo incremental.
            </p>
            <h4>Trabajamos con y para agencias de marketing, PYME, autónomos y organismos gubernamentales.</h4>
          </div>
          <div className="resume_details">
            <div className="resume_mile row">
              <div className="col-lg-3">
                <h3 className="rs-heading">servicios</h3>
              </div>
              <div className="col-lg-9">
                <div className="rs_info">
                  <p>Aquí tienes nuestros principales servicios: podemos desarrollar en PHP y Javascript, somos especialistas en <strong>Wordpress</strong> y <strong>Drupal</strong> ya que llevamos más de 10 años trabajando con estas herramientas.</p>
                  <div className="row">
                    <div className="col-md-4">
                      <ul className="lniks">
                        <li>Desarrollo web en Drupal</li>
                        <li>Desarrollo web en Wordpress</li>
                        <li>Optimizaciones SEO on Page</li>
                        <li>Desarrollo web en React</li>
                        <li>Scrapeo de datos</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul className="lniks">
                        <li>WPO</li>
                        <li>Headless CMS</li>
                        <li>Integraciones de API</li>
                        <li>Google Tag Manager</li>
                        <li>Active Campaign API</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul className="lniks">
                        <li>Integraciones con CRM</li>
                        <li>Animaciones Web</li>
                        <li>Mailchimp API</li>
                        <li>Integración de métodos de pago</li>
                        <li>Módulos y plugins a medida</li>
                      </ul>
                    </div>
                  </div>
                  <p style={{marginTop:'60px'}}><Link to="servicios" style={{color:'#dd2569'}}>Ver todos los servicios &rarr;</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Portfolio trabajos={props.data.allContentfulPortfolio.nodes} lang="es" />
      <Clientes clientes={props.data.allContentfulClientes.nodes} />
  <Testimonios testimonios={props.data.allContentfulTestimonios.nodes} />
    </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql`
{
  allContentfulClientes(limit: 100, sort: {order: ASC, fields: createdAt}, filter:{node_locale:{eq:"es"}}) {
    nodes {
      title
      id
      logo {
        gatsbyImageData(layout: CONSTRAINED, width: 150, quality:100)
      }
    }
  }
  allContentfulPortfolio(filter: {node_locale: {eq: "es"}}) {
    nodes {
      id
      title
      web
      tieneFicha
      slug
      image{
				gatsbyImageData(layout: CONSTRAINED, width: 600, quality:60)
      }
      texto {
        raw
      }
      tipoDeTrabajo{
				id
        name
      }
      
    }
  }
  allContentfulTestimonios(sort: { fields: [createdAt], order: DESC }, filter: {node_locale: {eq: "es"}}) {
    nodes {
      nombre
      cargo
      id
      imagen {
        gatsbyImageData(layout: FIXED, width: 70, quality: 70)
      }
      texto {
        raw
      }
    }
  }
}
`
